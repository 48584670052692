mat-dialog-container {
  border-radius: 4px !important;
  background-color: rgba(0, 0, 0, 0.8) !important;
  border: 2px solid #3369ff;
  padding: 0 !important;

  .mat-mdc-dialog-surface {
    box-shadow: none !important;
    background-color: transparent !important;
  }

  .mdc-dialog__surface::before {
    display: none !important;
  }

  .dialog-main-content {
    //height: 603px !important;
    position: relative !important;

    .header-modal-content {
      position: absolute;
      top: 20px;
      width: 100%;
      display: flex;
      justify-content: space-between;

      .modal-icon {
        padding: 0 0 0 20px;
      }

      .close-btn {
        padding: 0;
        width: 24px;
        height: 24px;
        margin-inline-end: 20px;
        min-width: unset !important;
      }

      h1 {
        font-family: "DavisSans" !important;
        font-size: 28px;
        font-weight: 400;
        line-height: 34px;
        text-transform: uppercase;
        text-align: center;
        border-bottom: 2px solid $prm-color;
        padding-bottom: 3px;
        padding-inline: 0px;
        margin-bottom: 0;
        color: $white-color !important;
      }
    }

    .modal-main-div {
      max-height: 40rem;
      position: relative;

      .modal-header-div {
        width: 100%;
        top: -24px;
        padding-bottom: 14px;
        margin-top: -14px;
        margin-bottom: 18px;
        border-bottom: 2px solid $prm-color;
        position: sticky;
        left: 0;
        z-index: 10;
        background-color: black !important;
      }

      h2 {
        margin-top: 6px;
      }
    }

    .action-buttons {
      @extend .flex-center;

      position: relative;
      width: 100%;

      .save-btn {
        width: 172px;
        height: 50px;
      }
    }

    form {
      padding-top: 55px;

      mat-form-field {
        margin: 0 8px;
        width: 16.4rem;

        // input,
        // textarea {
        //   font-size: 17px !important;
        // }

        mat-select {
          font-size: 16px !important;
        }
      }

      .modal-options {
        margin-bottom: 14px !important;
        margin-top: 13px !important;
        //height: 32px !important;
        flex-wrap: wrap;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
        padding-inline: 18px;
      }

      .text-area-field {
        width: 33.7rem !important;
      }

      .mat-mdc-dialog-content {
        padding: 0 !important;
        text-align: center;
        margin: 0;
        width: 100%;

        mat-form-field {
          width: 94%;
          margin-bottom: 20px !important;
          margin: 0;
        }
      }

      .mat-mdc-dialog-actions {
        padding: 16px;
        // position: absolute;
        // bottom: 35px;
        // left: 0;
        min-height: 0 !important;
        margin-bottom: 0 !important;
        width: 100%;

        button {
          height: 50px;
          min-width: 110px !important;
        }

        .remove-btn {
          position: absolute;
          bottom: 0;
          right: 35px;
          padding: 0;
          min-width: 0 !important;

          mat-icon {
            width: 24px !important;
            height: 32px !important;
            font-size: 2.2rem;
          }
        }

        .remove-btn:hover {
          mat-icon {
            color: $prm-hover-color;
          }
        }
      }
    }

    .save-btn {
      font-kerning: none;
      font-weight: 400;
      font-size: 20px;
      font-style: normal;
      line-height: 25px;
      text-transform: uppercase;
      width: 172px;
      height: 50px;
    }

    h1,
    button,
    span {
      color: $white-color;
    }

    p,
    li {
      color: $light-gray;
    }

    mat-checkbox {
      width: 100%;
      text-align: start;

      .mat-mdc-checkbox-label {
        font-weight: normal;
      }
    }
  }
}
