@import "src/assets/lib/bootstrap-grid.min.scss";
@import "src/theme.scss";

:root {
  font-size: 100%;
}

@font-face {
  font-family: "DavisSans";
  src: url(./assets/fonts/DavisSans-Regular.otf);
}

@font-face {
  font-family: "Stratos";
  src: url(./assets/fonts/Stratos-Regular.ttf);
}

html {
  overflow: hidden !important;
  background-color: #131314;
}

a {
  display: block;
  color: $white-color;
  text-decoration: none;
}

html,
body {
  height: 100%;
}
body {
  scroll-behavior: smooth;
  overflow-y: hidden;
  padding: 0;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

* {
  font-family: "DavisSans" !important;
  letter-spacing: unset !important;
}

h5 {
  color: $white-color !important;
  font-size: 1.16rem !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Stratos" !important;
}

h1::before {
  display: none !important;
}

td .clickable i svg {
  margin-bottom: -2px !important;
}

.full-width {
  width: 100%;
}

.fit-width {
  width: fit-content;
}

.sp-title {
  color: $light-gray;
  margin-inline-start: 8px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
}

.px-6p {
  padding-inline: 6px !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.main-div {
  width: 100vw;
  height: 100vh;
  background-color: $content-bg-color;
  overflow: hidden;
}

.div-sep {
  background-color: $medium-gray;
  height: 2px;
  width: 70%;
  margin: 1rem 0;
}

.width-8r {
  width: 8rem;
}

.mat-select-min-line {
  color: $level2-white;
}

.container {
  margin-top: 80px !important;
}

.my-20 {
  margin: 20px 0;
}

.my-20 {
  margin: 20px 0;
}

::-webkit-scrollbar {
  width: 0.8rem;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb {
  border-radius: 50px;
  border: 4px solid transparent;
  background-clip: content-box;
  background-color: $light-gray;
  width: 8px;
}

.smooth-modal {
  opacity: 0;
  transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);

  mat-dialog-container {
    overflow: hidden !important;
  }
}

.full-opacity {
  background-color: rgba(0, 0, 0, 1);
}

.mat-badge-content {
  top: 0px !important;
  right: -28px !important;
  width: 20px !important;
  height: 20px !important;
  line-height: 20px !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.pe-2 {
  padding-inline-end: 8px;
}

.background-blur,
.cdk-overlay-dark-backdrop {
  -moz-backdrop-filter: blur(6px) !important;
  -webkit-backdrop-filter: blur(6px) !important;
  -o-backdrop-filter: blur(6px) !important;
  backdrop-filter: blur(6px) !important;
  background-color: #000000a1 !important;
}

mat-icon {
  font-family: "Material Icons" !important;
}

.json-formatter-constructor-name {
  display: none !important;
}

.json-formatter-number,
.json-formatter-bracket {
  color: white !important;
}

@media (max-width: 420px) {
  :root {
    font-size: 70%;
  }
}

@import "./assets/styles/modal.scss";
@import "./assets/styles/controls.scss";

@media (min-width: 1680px) {
  // .demo-app,
  // .page-section {
  //   margin-top: 2rem !important;
  // }

  .container {
    margin-top: 66px !important;
  }

  .table-controls {
    margin-top: 3rem !important;
  }

  .top-controls {
    margin-top: 7rem !important;
  }
}
